<template>
  <div class="page">
    <div class="banner">
      <img :src="require('@/assets/home/wj_banner_jskh.png')" alt="" />
    </div>
    <div class="title">请选择考核问卷</div>

    <van-tabs v-if="type == '0'" :active="active" sticky color="#1492ff">
      <van-tab title="待自评" name="1" class="tab">
        <info :type="type" :active="1"></info>
      </van-tab>
      <van-tab title="历史自评" name="2" class="tab">
        <info :type="type" :active="2"></info>
      </van-tab>
    </van-tabs>

    <info v-if="type == '1'" :type="type" :active="1"></info>
  </div>
</template>

<script>
import info from "./info.vue";
export default {
  components: { info },
  data() {
    return {
      type: 0,
      active: 1,
    };
  },
  mounted() {
    if (this.$route.query.type == "0") {
      document.title = "自评记录";
    }
  },
  created() {
    var self=this;
    console.log(self.$route.query.type)
    this.type = self.$route.query.type;
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.page {
  background: #f4f8fb;
}
.page{
    .tab{
        margin-top: 10px;
    }
}

.banner {
  img {
    width: 100%;
  }
}

.title {
  color: #98a3ad;
  text-align: center;
  margin: 10px 0;
}
</style>